define("secret-santa/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pBIAfa6B",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row py-5\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col text-center\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"Secret 🎅🏼 Santa\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"w-100\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col text-center\"],[12],[2,\"\\n\"],[6,[37,3],[[35,1,[\"isAuthenticated\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1,[\"currentUser\",\"email\"]],\"antoine.lagadec@idol.io\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"link-to\",[[24,0,\"btn btn-link\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"Home\"]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"btn btn-link\"]],[[\"@route\"],[\"draw\"]],[[\"default\"],[{\"statements\":[[2,\"Draw\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[11,\"button\"],[24,0,\"btn btn-link\"],[4,[38,0],[[32,0],\"logout\"],null],[12],[2,\"Sign out\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"btn btn-link\"],[4,[38,0],[[32,0],\"login\"],null],[12],[2,\"Sign in with Google\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\\n\"],[8,\"canvas-snow\",[],[[],[]],null],[2,\"\\n\"],[8,\"notification-container\",[],[[\"@zindex\"],[\"9999\"]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"session\",\"eq\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "secret-santa/templates/application.hbs"
    }
  });

  _exports.default = _default;
});