define("secret-santa/controllers/me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MeController extends Ember.Controller {}

  _exports.default = MeController;
});