define("secret-santa/templates/me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i/GnZOdM",
    "block": "{\"symbols\":[\"nav\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n      \"],[8,\"bs-nav\",[],[[\"@type\",\"@justified\",\"@stacked\",\"@fill\"],[\"pills\",false,false,false]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,[32,1,[\"link-to\"]],[],[[\"@route\"],[\"me.index\"]],[[\"default\"],[{\"statements\":[[2,\"My Profile\"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,1,[\"item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,[32,1,[\"link-to\"]],[],[[\"@route\"],[\"me.santa\"]],[[\"default\"],[{\"statements\":[[2,\"My Secret Santa\"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "secret-santa/templates/me.hbs"
    }
  });

  _exports.default = _default;
});