define("secret-santa/controllers/draw", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function assignSantas(array) {
    var matches = [];

    if (!array || !array.length) {
      return null;
    }

    var santas = array.slice();
    shuffle(santas);

    for (var i = 0; i < santas.length; i++) {
      var santa = santas[i],
          recipient; // Assign santa to the person next to them to avoid assigning to self and avoid duplicate recipients

      if (i !== santas.length - 1) {
        recipient = santas[i + 1];
      } else {
        recipient = santas[0];
      }

      santa.recipient = recipient;
      recipient.sender = santa;
      matches.push(santa);
    }

    return matches;
  }

  ;

  function shuffle(array) {
    var n = array.length,
        i,
        j;

    while (n) {
      i = Math.floor(Math.random() * n--);
      j = array[n];
      array[n] = array[i];
      array[i] = j;
    }
  }

  let DrawController = (_dec = Ember.inject.service(), _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class DrawController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);
    }

    async rollTheDice() {
      let users = this.model; // for (let i = 0; i < 15; i++) {
      //   let user = this.store.createRecord('user', { email: faker.internet.email(), displayName: faker.name.firstName() + ' ' + faker.name.lastName() });
      //   user.save();
      // }

      assignSantas(users).map(user => {
        return user.save();
      });
    }

    async reset() {
      let users = this.model;
      users.forEach(user => {
        user.recipient = null;
        user.sender = null;
        user.save();
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "rollTheDice", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "rollTheDice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype)), _class));
  _exports.default = DrawController;
});