define("secret-santa/components/paper-dialog-content", ["exports", "ember-paper/components/paper-dialog-content"], function (_exports, _paperDialogContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _paperDialogContent.default;
    }
  });
});