define("secret-santa/templates/me/santa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QOr9plfw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row my-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-8\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0,[\"recipient\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[2,\"\\n          \"],[10,\"h5\"],[12],[2,\"Your secret santa is\"],[13],[2,\"\\n          \"],[10,\"pre\"],[12],[1,[35,0,[\"recipient\",\"displayName\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"\\n          \"],[10,\"h5\"],[12],[2,\"His/her address is\"],[13],[2,\"\\n          \"],[10,\"pre\"],[12],[1,[35,0,[\"recipient\",\"address\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"\\n          \"],[10,\"h5\"],[12],[2,\"His/her phone number is\"],[13],[2,\"\\n          \"],[10,\"pre\"],[12],[1,[35,0,[\"recipient\",\"phone\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"\\n          \"],[10,\"h5\"],[12],[2,\"He/she likes\"],[13],[2,\"\\n          \"],[10,\"pre\"],[12],[1,[35,0,[\"recipient\",\"like\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"\\n          \"],[10,\"h5\"],[12],[2,\"He/she dislikes\"],[13],[2,\"\\n          \"],[10,\"pre\"],[12],[1,[35,0,[\"recipient\",\"dislike\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        No secret santa assigned yet, come back later !\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "secret-santa/templates/me/santa.hbs"
    }
  });

  _exports.default = _default;
});